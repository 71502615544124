import * as React from 'react';

// export interface IFormProps { getWeather: JSON; }
export interface IFormProps {
	getWeather: any;
}

class Form extends React.Component<IFormProps> {
	render() {
		return (
			<form onSubmit={this.props.getWeather}>
				<input type="text" name="zip" placeholder="Zip code..." />
				<button>Get Weather</button>
			</form>
		);
	}
}

export default Form;
