import * as React from 'react';
import Form from './components/Form';
import Portfolio from './components/Portfolio';

interface IState {
	name: string,
	description: string,
	samples: string[],
}

class About extends React.Component {
	state = {
		name: '',
		description: '',
		samples: [],
}	

	async componentDidMount()  {
		try {
			const apiResult = await fetch(`https://kevj.com/api/samples`);
			const data = await apiResult.json();
			console.log(data);
			this.setState(
				{
					name: data.name,
					description: data.description,
					samples: data
				}
			)			
		} catch (e) {
			console.log(e);
		}
	}

	public getSample = async (e: any) => {
		e.preventDefault();
		const apiResult = await fetch(`https://kevj.com/api/samples`);
		const data = await apiResult.json();
		console.log(data);
		this.setState(
				{
						name: data.name,
						description: data.description,
						samples: data
				}
		)
	}

	public render() {	
		return (
			<div>
				<h1>About</h1>
				<p><a href="https://jameshoma.com">James Homa</a> is a Senior Applications Developer with web, mobile and other development experience.</p>
				<p>Most days he writes code and continues to learn about the craft. Otherwise, James tries to be outside biking, hiking, playing in a lake or snowshoeing depending on the weather. Music is on more often than not.</p>
				<Portfolio samples={this.state.samples} />
				<p><a href="https://www.linkedin.com/in/james-homa/">LinkedIn</a></p>
			</div>
		);
	}
}

export default About;
