import * as React from 'react';
import NavBar from "./NavBar";
import Routes from './routes';

const Layout = () => {
    return (
        <div>
            <NavBar />
            <Routes />
        </div>
    );
}

export default Layout;
