import * as React from 'react';
import { NavLink } from "react-router-dom";

const Navigation = () => {
    return (
        <div> 
            <NavLink to="/">Home</NavLink>&nbsp;|&nbsp;
            <NavLink to="/photos">Photos</NavLink>&nbsp;|&nbsp;        
            <NavLink to="/weather">Weather</NavLink>&nbsp;|&nbsp;
            <NavLink to="/about">About</NavLink>
        </div>
    );
}

export default Navigation;
