
import * as React from 'react';
import {
  GridList, 
  GridListTile,
  GridListTileBar ,
  IconButton,
  Button,
  Dialog,
  DialogActions
} from '@material-ui/core'
import {Image} from '../models'
import ZoomInIcon from '@material-ui/icons/ZoomIn'

interface IProps {
  images: Image[];
}

class ImageList extends React.Component<IProps> {
  state = { 
    open: false,
    currentImage: ''
  }

  handleClickOpen = (img: string) => {
    this.setState({
      open: true,
      currentImage: img
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  
  public render() {

    let imageListContent;
    const { images } = this.props;		
    <h3>hey</h3>
    if (images) {
      imageListContent =  (
        <GridList cols={3}>
          {images.map((img, index) => (
            <GridListTile key={index} >
              <img src={img.largeImageURL} alt="test" />  
              <GridListTileBar 
                title={img.tags} 
                subtitle={
                  <span>
                    by <strong>{img.user}</strong>
                  </span>
                }
                actionIcon={
                  <IconButton onClick={() => this.handleClickOpen(img.largeImageURL)}>
                    <ZoomInIcon style={{color: 'white'}} />
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
        </GridList>
      )
    } else {
      imageListContent = null;
    }

    const actions = [ 
      <Button color="primary" onClick={this.handleClose}>Close</Button>
    ]

		return (
			<div>
        {imageListContent}
        <Dialog open={this.state.open}>
          <DialogActions>
            actions={actions} 
            onRequestClose={this.handleClose} 
            modal={false}
          </DialogActions>
          <img src={this.state.currentImage} alt="yo" style={{width: '100%'}} />
        </Dialog> 
			</div>
		);
	}
}

export default ImageList;
