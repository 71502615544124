import * as React from 'react';
// import about from "../features/about";
import AppBar from '@material-ui/core/AppBar'
import HomaLogo from '../assets/images/icon192x192.png'
import { BrowserRouter } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Toolbar';

const NavBar = () => {

	return (
		<div>
			<BrowserRouter>
				<AppBar position='static'>
					<Toolbar>
						<Typography variant='title' color='#2b3b44'>
							<img src={HomaLogo} alt="James Homa" width="40" height="40" />
							&nbsp;
							<a href="/">Home</a>&nbsp;|&nbsp;
							<a href="/Photos">Photos</a>&nbsp;|&nbsp;
							<a href="/Weather">Weather</a>&nbsp;|&nbsp;
							<a href="/about">About</a>
						</Typography>
					</Toolbar>
				</AppBar>
			</BrowserRouter>
		</div>
	)
}

export default NavBar;
