import * as React from 'react';
import './App.css';
import Layout from './components/Layout';

class App extends React.Component {
  render() {
    return (
        <Layout />
    );
  }
}

export default App;
