import * as React from 'react';
import Search from './components/Search';

const Photos = () => {

	return (
		<div>
			<h1>Photos from &nbsp;
				<a href='https://pixabay.com/'>
					<img src='https://pixabay.com/static/img/logo_square.png' width='30' height='30' />
				</a>
			</h1>
			<Search />
		</div>
	);
}

export default Photos;
