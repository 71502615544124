import * as React from 'react';

const Header = () => {

    return (
        <div>
            <h1>Weather</h1>
        </div>
    )
}

export default Header;