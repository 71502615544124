import * as React from 'react';

const Home = () => {
	return (
		<div>
			<h1>James Homa</h1>
			<p><a href="https://jameshoma.com">James Homa</a> creates collaborative solutions that exceed expectations and respect the bottom line</p>
		</div>
	);
}

export default Home;
