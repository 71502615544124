import * as React from 'react';
import Form from './components/Form';
import Header from './components/Header';
import Weather from './components/Weather';

interface IState {
	temperature: string,
	error: string
}

class Junk extends React.Component<IState> {
	
	state = {
    city: '',
    country: '',
    description: '',
    error: '',
    humidity: '',
    temperature: ''
  }

  getWeather = async (e: any) => {
    e.preventDefault();
    const zip = e.target.elements.zip.value;
    const API_KEY = "8185df13f6638d308a060cf507c725a7";
    const apiResult = await fetch(`https://api.openweathermap.org/data/2.5/weather?zip=${zip},us&appid=${API_KEY}&units=imperial`);
    const data = await apiResult.json();
    
    if(zip){
      console.log(data);
      this.setState(
        {
          temperature: data.main.temp,
          city: data.name,
          country: data.sys.country,
          humidity: data.main.humidity,
          description: data.weather[0].description,
          error: ''        
        }
      )
    }
    else {
      this.setState({
          temperature: undefined,
          city: undefined,
          country: undefined,
          humidity: undefined,
          description: undefined,
          error: ''        
        }
      );
    }
  }

  render() {
		return (
			<div className="App">
					<Header />
					<Form getWeather={this.getWeather} />
          <Weather 
            temperature={this.state.temperature} 
            city={this.state.city} 
            country={this.state.country} 
            humidity={this.state.humidity} 
            description={this.state.description} 
            error={this.state.error}           
          />
			</div>
		);
	}
}

export default Junk;
