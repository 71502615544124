import * as React from 'react';

// interface IProps {
// 	temperature: string
// }

interface IProps {
    city: string;
    country: string;
    humidity: string;
    description: string;
    error: string;
    temperature: string;
}

class Weather extends React.Component<IProps> {
	render() {
		return (
			<div>
				{this.props.city && this.props.country && <p>Location: {this.props.city}, {this.props.country}</p>}
				{this.props.temperature && <p>Temperature {this.props.temperature}</p>}
				{this.props.humidity && <p>Humidity: {this.props.humidity}</p>}
				{this.props.description && <p>Conditions: {this.props.description}</p>}
				{this.props.error && <p>{this.props.error}</p>}
			</div>
		)
	}
}

export default Weather;
