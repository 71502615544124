import * as React from 'react';
import {Sample} from '../models'
import { GridList, GridListTile } from '@material-ui/core'

interface IProps {
  samples: Sample[];
}

class Portfolio extends React.Component<IProps> {

	public render() {
    let sampleListContent;
		const { samples } = this.props;		
		<h1>port</h1>

		if(samples) {
			sampleListContent = (

				<div>
					<h3>Portfolio</h3>
				 	{samples.map((sample, index) => (
						 <p key={index}>
								<h4>{sample.name}</h4>
								{sample.description}<br />
								<a href={sample.url}>{sample.url}</a>
						 </p>
					))}
						
					{/* <GridList cols={3}>`
						{samples.map((sample, index) => (
							<GridListTile key={index} >
									<h4>{sample.name}</h4>
									{sample.description}<br />
									<a href={sample.url}>{sample.url}</a>
							</GridListTile>
							))}
					</GridList> */}

				</div>
			)
		}
		else {
			sampleListContent = null;

		}

		return (
			<div>
				{sampleListContent}
			</div>
		);
	}

}

export default Portfolio;