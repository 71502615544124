import * as React from 'react';
import {
	TextField,
	Select,
	MenuItem
} from '@material-ui/core'
import ImageList from './ImageList'

interface IState {
	amount: number,
	apiUrl: string,
	images: string[],
  searchText: string
}

class Search extends React.Component {

  state = {
		amount: 15,
		apiUrl: 'https://pixabay.com/api', 
		images: [],
		searchText: ''
	}	
	
	public handleTextChange =  (e: any) => {
		const targetValue: string = e.target.value;
		
		this.setState({[e.target.name]: targetValue}, async () => {
			if (targetValue === '') {
				this.setState({images: []})
			} else {
				const API_KEY = "11502488-afce72bc5c59b461390a31cee";
				const apiResult = await fetch(`${this.state.apiUrl}/?key=${API_KEY}&q=${this.state.searchText}&image_type=photo&per_page=${this.state.amount}&safesearch=true`);
				const data = await apiResult.json();
				this.setState({images: data.hits})
	
				console.log(this.state.images);
				}
		});
	}

	public handleAmountChange = ( e: any) => {
		// this.setState({amount: value})
		this.setState({ [e.target.name]: e.target.value });
	}

	public render() {

		return (
			<div>
				<TextField 
					name="searchText"
					value={this.state.searchText}
					onChange={this.handleTextChange}
					label="Search for Images"
					fullWidth={true}
				/>
				<Select
            value={this.state.amount}
            onChange={this.handleAmountChange}
            inputProps={{
              name: 'amount',
              id: 'amount-simple',
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
					{this.state.images.length > 0 ? (<ImageList images={this.state.images} />) : null}
			</div>
		);
	}
}

export default Search