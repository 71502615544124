import * as React from 'react';
import { BrowserRouter, Route, Switch, NavLink } from 'react-router-dom';
import about from "../../features/about";
import home from "../../features/home";
import photos from "../../features/photos";
import weather from "../../features/weather";
import Navigation from '../Navigation';

const Routes = () => {
	return (
		<BrowserRouter>
			<div>
				<Switch>
					<Route path="/" exact={true} component={home} />
					<Route path="/photos" component={photos} />
					<Route path="/weather" component={weather} />
					<Route path="/about" component={about} />
				</Switch>
				<Navigation />
			</div>
		</BrowserRouter>
	);
}

export default Routes;
